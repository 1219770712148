import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/auth/Login";
import Welcome from "./pages/auth/Welcome";
import PageInvest from "./pages/PageInvest/PageInvest";
import PageInvestments from "./pages/PageInvestments/PageInvestments";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { CardBienvenida } from "./components/CardBienvenida/CardBienvenida";
import Protected from "./components/Auth/Protected";
import NoProtected from "./components/Auth/NoProtected";
import { GetAuth, SetAuth } from "./helpers/Auth";
import PageMovements from "./pages/PageMovements/PageMovements";
import PageAccount from "./pages/PageAccount/PageAccount";
import PageReferals from "./pages/PageReferals/PageReferals";
import PageSecurity from "./pages/PageSecurity/PageSecurity";
import PageAcademy from "./pages/PageAcademy/PageAcademy";
import PageHelpSupport from "./pages/PageHelpSupport/PageHelpSupport";
import PageDashboard from "./pages/PageDashboard/PageDashboard";
import PageProjectDetail from "./pages/PageProjectDetail/PageProjectDetail";
import PageAcademyDetails from "./pages/PageAcademyDetails/PageAcademyDetails";
import PageReegister from "./pages/auth/Register";
import { RegisterForm, StateParamWrapper } from "./pages/auth/RegisterForm";
import AccountCreated from "./pages/AccountCreated/AccountCreated";
import PageImcompleteProfile from "./pages/PageImcompleteProfile/PageImcompleteProfile";
import PageWithdrawal from "./pages/PageWithdrawal/PageWithdrawal";
import PageDeposit from "./pages/PageDeposit/PageDeposit";
import PageMyAssets from "./pages/PageMyAssets/PageMyAssets";
import { havePermission } from "./helpers/protectRoutes";
import Page404 from "./pages/Page404";

const _ROUTES = havePermission();

const MainRoutes = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [auth, setAuth] = useState(null);
  useEffect(() => {
    
    let AUTH = GetAuth();
    if (AUTH) {
      setAuth(AUTH);
    }
    /* console.log("AUTH:",AUTH); */
  }, []);

  const checkAuth = (isLogin) => {
    console.log("MainRoutes checkAuth 1:", isLogin);
    setIsSignedIn(isLogin);
    console.log("MainRoutes checkAuth 2:", isSignedIn);
    return isSignedIn;
  };

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Protected checkAuth={isSignedIn}>
              <Dashboard />
            </Protected>
          }
        >
          <Route path="/" element={<Navigate to="dashboard" replace />} />

          {/* <Route path="/dashboard" element={<PageDashboard />} /> */}
          {/* <Route path={ROUTES.PageDashboard.path} element={<ROUTES.PageDashboard.element />} /> */}
          {_ROUTES.map((item, i) => {
            return (
              auth &&
              item.roleAccess.includes(auth.user.role) && (
                <Route
                  key={`path-${i}`}
                  path={item.path}
                  element={<item.element Auth={auth} />}
                />
              )
            );
          })}
          {/* <Route path="/invertir" element={<PageInvest />} /> */}
          {/* <Route
            path="/invertir/proyecto/:id"
            element={<PageProjectDetail />}
          /> */}
          {/* <Route path="/mis-inversiones" element={<PageInvestments />} /> */}
          {/* <Route path="/mis-movimientos" element={<PageMovements />} /> */}
          {/* <Route path="/mis-activos" element={<PageMyAssets />} /> */}
          {/* <Route path="/cuenta" element={<PageAccount />} /> */}
          {/* <Route path="/solicitud-de-retiro" element={<PageWithdrawal />} /> */}
          {/* <Route path="/recarga-tu-cuenta" element={<PageDeposit />} /> */}

          {/* <Route path="/referidos" element={<PageReferals />} /> */}
          {/* <Route path="/seguridad" element={<PageSecurity />} /> */}
          {/* <Route path="/academia" element={<PageAcademy />} /> */}
          <Route
            path="/completar-perfil"
            element={<PageImcompleteProfile Auth={auth} />}
          />
          {/* <Route
            path="/academia/detalles-de-artículo"
            element={<PageAcademyDetails />}
          /> */}
          {/*  <Route path="/ayuda-y-soporte" element={<PageHelpSupport />} /> */}

          <Route path="*" element={<Page404 />} />
        </Route>
        <Route
          path="/welcome"
          element={
            <NoProtected checkAuth={isSignedIn}>
              <Welcome />
            </NoProtected>
          }
        />
        <Route
          path="/login"
          element={
            <NoProtected checkAuth={isSignedIn}>
              <Login />
            </NoProtected>
          }
        />
        <Route
          path="/register"
          element={
            <NoProtected checkAuth={isSignedIn}>
              <PageReegister />
            </NoProtected>
          }
        />
        <Route
          path="/register/:code"
          element={
            <NoProtected checkAuth={isSignedIn}>
              <PageReegister />
            </NoProtected>
          }
        />
        <Route
          path="/register/cuenta-creada"
          element={
            <NoProtected checkAuth={isSignedIn}>
              <AccountCreated />
            </NoProtected>
          }
        />

        <Route element={<StateParamWrapper />}>
          <Route
            path="/register/form"
            element={
              <NoProtected checkAuth={isSignedIn}>
                <RegisterForm />
              </NoProtected>
            }
          />
          <Route
            path="/register/form/:code"
            element={
              <NoProtected checkAuth={isSignedIn}>
                <RegisterForm />
              </NoProtected>
            }
          />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </div>
  );
};

export default MainRoutes;
