// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React from "react";
import BadgesProjects from "../BadgesProjects/BadgesProjects/BadgesProjects";
import Etiqueta from "../EtiquetaInvertir/EtiquetaInvertir";

import styles from "./CardProyect.module.css";
import './CardProyect.css';

const CardResumen = () => {
  return (
    <div className={styles.cardResumen}>
      <div className={styles.contentCard}>
        <div className={styles.frame551}>
          <div className={styles.image}>
            <img
              alt=""
              className={styles.img}
              src="https://static.overlay-tech.com/assets/3b229cdf-fe9b-4133-9c9f-b15332010488.png"
            />
          </div>
          <div className={styles.frame550}>
            <div className="float-labels">
              <div className="float-label-left"> <Etiqueta /></div>
              <div className="float-label-right"><BadgesProjects text="Proyecto de Infraestructura"/></div>
            </div>
          </div>
        </div>
        <div className={styles.frame562}>
          <div className={styles.dataCard}>
            <div className={styles.frame558}>
              <div className={styles.nombreYPrecio}>
                <label className={styles.body1}>
                  Edificio Jardín Central
                </label>
                <label className={styles.body1Bold}>
                  $2.002.015.350
                </label>
              </div>
              <div className={styles.ubicacionYLabel}>
                <label className={styles.caption}>
                  Ciudad: Cali, Valle
                </label>
                <label className={styles.captionTwo}>
                  Valor del proyecto
                </label>
              </div>
            </div>
            <img
              alt=""
              className={styles.separador}
              src="https://static.overlay-tech.com/assets/ec0f185b-91dc-4e8e-ba54-87c378f43a98.svg"
            />
            <div className={styles.dataCard}>
              <div className={styles.labels}>
                <div className={styles.recaudo}>
                  <p className={styles.captionThree}>
                    % de recaudo
                  </p>
                  <p className={styles.body2Bold}>35%</p>
                </div>
                <div className={styles.tir}>
                  <p className={styles.captionFour}>
                    TIR E.A
                  </p>
                  <div className={styles.iconhelpTwo}>
                    <img
                      alt=""
                      className={styles.iconhelp}
                      src="https://static.overlay-tech.com/assets/6fcf4f50-9a7c-4510-908b-9136cce1c1c7.svg"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.indicadores}>
                <img
                  alt=""
                  className={styles.statusBar}
                  src="https://static.overlay-tech.com/assets/39a1e7f4-0e2b-4340-a531-f307fe09e197.svg"
                />
                <div
                  className={styles.indicadorRendimiento}
                >
                  <label className={styles.body2Bold}>
                    2.55 - 3.55%
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardResumen;