import { useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import CardResumen from "../../components/CardProject/CardProject";
import CoverCategoria from "../../components/CoverCategoryProject/CoverCategoryProject";
import "./PageInvest.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1350 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1350, min: 720 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 720, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function PageInvest() {
  const [showCarousel,setShowCarousel] = useState("none");
  useEffect( ()=>{
    setTimeout( ()=>{
      setShowCarousel("grid");
    },10);
  },[] );
  const navigate = useNavigate();
  const handlerClic = (id) => {
    console.log("Redirect:",id);
    navigate("/invertir/proyecto/"+id);
  }
  return (
    <div className="page-invest container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="table-title blue">Categorias</div>
        </div>

        <div className="carousel-box">
          <div className="carousel-container container-animated" style={ {display:showCarousel} }>
            <Carousel 
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              /* removeArrowOnDeviceType={["tablet", "mobile"]} */
              removeArrowOnDeviceType={["mobile"]}
              deviceType={"desktop"}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              <div className="carousel-item-img">
                <CoverCategoria
                  tipo="Residencial"
                  src="https://static.overlay-tech.com/assets/5206383c-767b-48b6-9789-debab4e005e6.png"
                />
              </div>
              <div className="carousel-item-img">
                <CoverCategoria
                  tipo="Comercial"
                  src="https://static.overlay-tech.com/assets/5206383c-767b-48b6-9789-debab4e005e6.png"
                />
              </div>
              <div className="carousel-item-img">
                <CoverCategoria
                  tipo="Oficinas"
                  src="https://static.overlay-tech.com/assets/5206383c-767b-48b6-9789-debab4e005e6.png"
                />
              </div>
              <div className="carousel-item-img">
                <CoverCategoria
                  tipo="Industrial"
                  src="https://static.overlay-tech.com/assets/5206383c-767b-48b6-9789-debab4e005e6.png"
                />
              </div>
              <div className="carousel-item-img">
                <CoverCategoria
                  tipo="Infraestructura"
                  src="https://static.overlay-tech.com/assets/5206383c-767b-48b6-9789-debab4e005e6.png"
                />
              </div>
            </Carousel>
          </div>
        </div>

        {/* <div class="container-fluid">
      <div className="col-md-3">
          <CoverCategoria />
        </div>
        <div className="col-md-3">
          <CoverCategoria />
        </div>
      </div> */}
      </div>
      <div className="row">
      <div className="col-md-12">
          <div className="table-title blue">Proyectos</div>
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(1)}>
          <CardResumen  />
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(2)}>
          <CardResumen />
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(3)}>
          <CardResumen />
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(4)}>
          <CardResumen />
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(5)}>
          <CardResumen />
        </div>
      </div>
    </div>
  );
}
