import React from "react";
import styles from "./BadgesProjects.module.css";
import './BadgesProjects.css';
const BadgesProjects = (props) => {
  return (
    <div className={styles.tipo}>
      <label style={props.labelStyle} className={`bg-box ${props.className}`}>
        {props.text}
      </label>
    </div>
  );
};

export default BadgesProjects;