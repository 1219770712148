import React, { useCallback, useEffect, useState } from "react";
/* import Logo from "../../assets/Logo/Logo"; */
import Dropdown from "react-bootstrap/Dropdown";

import "./Welcome.css";
import imagen from "../../assets/img_main.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./HeaderWelcome.module.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/Logo/Logo";
import phone from "../../assets/img_mockup_phone.png";
import capture from "../../assets/CAPTURE.png";

import captureApp from "../../assets/img_mockup_pantalla.png";
import Boton from "../../components/Boton";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CardBlog } from "../../components/CardBlog/CardBlog";
import CoverCategoria from "../../components/CoverCategoryProject/CoverCategoryProject";
import { StayInformed } from "../../components/StayInformed/StayInformed";
import { grecapchaWebSite, grecapchaSecret } from "../../constants/global";
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import axios from "axios";
import { UseReCaptchaV3 } from "../../components/UseReCaptchaV3/UseReCaptchaV3";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1350 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1150, min: 720 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 720, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
function Welcome() {
  const navigate = useNavigate();

  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token) => {
    setToken(token);
  });

  const doSomething = () => {
    /* do something like submit a form and then refresh recaptcha */
    setRefreshReCaptcha((r) => !r);
  };

  const [showCarousel, setShowCarousel] = useState("none");
  useEffect(() => {
    setTimeout(() => {
      setShowCarousel("grid");
    }, 100);
  }, []);
  useEffect(() => {
    //const height = document.getElementsByClassName("column-rigth")[0];
    const height = 10;
    const element = document.getElementsByClassName("welcome-img")[0];

    new Promise((resolve) =>
      setTimeout(() => {
        element.style.height = height.clientHeight + "px";
        //let imgW = element.getElementsByTagName('img')[0].style.width = height.clientWidth + "px"
        //let imgW = element.getElementsByTagName('img')[0].style.width = (height.clientWidth - 45) + "px"
        //let imgH = element.getElementsByTagName('img')[0].style.height = height.clientHeight + "px"
        resolve(true);
      }, 10)
    );

    function handleResize() {
      let height = document.getElementsByClassName("column-rigth")[0];
      if (height) {
        //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
      }

      /* let element = document.getElementsByClassName('login-img')[0];
      element.style.minHeight = height.clientHeight + "px"; */

      //let imgW = element.getElementsByTagName('img')[0].style.width = (height.clientWidth - 45) + "px"
      //let imgH = element.getElementsByTagName('img')[0].style.height = height.clientHeight + "px"
    }

    window.addEventListener("resize", handleResize);
  });
  const toRegister = () => {
    navigate("/register");
  };
  const toLogin = () => {
    navigate("/login");
  };
  const toAbout = () => {
    console.log("toAbout");
    //navigate("/login");
  };
  const toFaqs = () => {
    console.log("toFaqs");
    //navigate("/login");
  };
  const toContactUs = () => {
    console.log("toContactUs");
    //navigate("/login");
  };
  const content2 = () => {
    return (
      <div style={{ fontSize: "13px", textAlign: "start", color: "#4d4d4d" }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </div>
    );
  };
  const content3 = () => {
    return (
      <div
        style={{ marginTop: "10px", marginBottom: "20px", cursor: "pointer" }}
      >
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Bold",
            padding: "3px 10px",
            background: "#b9de2c",
            borderRadius: "5px",
          }}
        >
          Ver más
        </div>
      </div>
    );
  };

  return (
    <div className="Welcome container-animated">
      <div
        style={{
          position: "fixed",
          width: "100%",
          backgroundColor: "rgba(255,255,255,.8)",
          backdropFilter: "blur(10px)",
          zIndex: 999,
        }}
        className={styles.headerDashboard}
      >
        <div>
          <div className="menu-text">
            <Logo width="75%" height="80%" />
          </div>
        </div>

        <div className="container-buttons-dropdown container-animated">
          {/* <Dropdown>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <FontAwesomeIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: "#787878",
                  cursor: "pointer",
                }}
                
                icon={faBars}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/about">
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toAbout} className="link-about">
                      Sobre Devise
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/faqs" active>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toFaqs} className="link">
                      Preguntas frecuentes
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/contact-us">
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toContactUs} className="link">
                      Contáctanos
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div className="btn-cancel">
                      <Boton
                        className={"btn _btn-primary font-14"}
                        handlerClick={toRegister}
                        style={{
                          borderRadius: "8px",
                          width: "115px",
                          background: "white",
                          border: "1px solid #112e6d",
                          color: "#112e6d",
                        }}
                        label={"Registrarse"}
                      />
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div className="btn-logout">
                      <Boton
                        className={"btn _btn-primary font-14"}
                        style={{ borderRadius: "8px", width: "145px" }}
                        label={"Entrar a Devise"}
                        handlerClick={toLogin}
                      />
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      <div className="welcome-container">
        <div className="container">
          <div className="column-left box">
            <div className="welcome-img">
              <div className="img-container">
                <img src={imagen} />
              </div>
              <div className="text-banner">
                <div className="text-header big-h1 primary">
                  Reinventando el{" "}
                  <span className="secondary-lightblue">
                    valor Inmobiliario
                  </span>
                </div>
                <div className="text-small primary">
                  Invertir en proyectos inmobiliarios nunca había sido tan
                  fácil, rápido y seguro.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ paddingTop: "20px", paddingBottom: "20px", height: "100%" }}
        className="welcome-container"
      >
        <div style={{ width: "90%", margin: "auto" }}>
          <StayInformed />
        </div>
      </div>
      <div
        style={{ paddingTop: "10px", paddingBottom: "20px", height: "100%" }}
        className="welcome-container section-purple"
      >
        <div className="row-section">
          <div className="col-md-9 section">
            <div className="section-box">
              <h1 className="big-h1 white align-self-center">
                Invertir en inmuebles{" "}
                <span className="secondary">a un solo click</span>
              </h1>
              <p className="white">
                Nuestra plataforma está en construcción y muy pronto podrás
                unirte a nuestro proyecto para revolucionar la forma en que
                inviertes en el sector inmobiliario. Únete a nosotros en esta
                emocionante aventura financiera.
              </p>
              <p className="white">
                Descubrirás una nueva forma de invertir en activos comerciales
                inmobiliarios con nuestra plataforma. Simplificamos el proceso
                de inversión en activos que antes eran inaccesibles para ti.
              </p>
              <p className="white">
                Desde la comodidad de tu hogar, podrás acceder a oportunidades
                de inversión en bienes raíces de manera rápida y segura.
              </p>
            </div>
          </div>
          <div style={{ overflow: "hidden" }} className="col-md-3 section">
            <img className="img-border" src={phone} />
          </div>
        </div>
      </div>

      <div
        style={{
          position: "flex",
          width: "100%",
          backgroundColor: "rgba(255,255,255,.8)",
          backdropFilter: "blur(10px)",
          height: "100px",
          maxWidth: "1500px",
          margin: "10px auto",
        }}
      >
        <div>
          <div className="menu-text" style={{ marginTop: "-15px" }}>
            <Logo width="75%" height="80%" />
          </div>
        </div>

        <div
          className="custom-footer"
          style={{ fontSize: "11px", marginTop: "20px" }}
        >
          <span style={{ paddingTop: "30px" }}>
            Copyright © Lincep. All right reserved
          </span>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
