import { useCallback, useEffect, useState } from "react";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import BreadcrumbsComponent from "../../../components/Breadcrumbs/BreadcrumbsComponent";
import { useNavigate, useParams } from "react-router-dom";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import Page404 from "../../Page404";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./AdminAssetSponsorView.css";
import BadgesProjects from "../../../components/BadgesProjects/BadgesProjects/BadgesProjects";
import {
  classNameProyect,
  getResourceFromUrl,
  numFormatter,
} from "../../../helpers/functions";
import { Tab, Tabs } from "react-bootstrap";
import DatosBasicos from "../../PageAccount/DatosBasicos/DatosBasicos";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import {
  IconBoxEye,
  IconBoxReload,
  IconBoxReloadOutLine,
  IconBoxWallet,
} from "../../../components/Icons/Icons";
/* import Chat from "../../../components/chat/Chat"; */
import Boton from "../../../components/Boton";
import { Dialog } from "@mui/material";
import Chat from "../../../components/chat/Chat";
import Modal from "react-modal";
import { StatusData } from "../../../constants/status";

import { InvertColors } from "../../../helpers/ColorsUtils";
import { Host } from "../../../constants/global";
import PdfViewer from "../../../components/PdfViewer/PdfViewer";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
//Modal.setAppElement('#AdminAssetSponsorView');
export default function AdminAssetSponsorView() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [paso, setPaso] = useState(0);
  const [archivo, setArchivo] = useState(null);
  const [fideicomisoFirmado, setFideicomisoFirmado] = useState(null);
  const [minutaArchivo, setMinutaArchivo] = useState(null);
  const [nuevaEscritura, setNuevaEscritura] = useState(null);
  const [
    nuevoCertificadoTradicionTibertad,
    setNuevoCertificadoTradicionTibertad,
  ] = useState(null);
  let subtitle;
  function openModal() {
    setIsOpen(true);
    //Modal.setAppElement('body');
  }

  const textEstados = StatusData;

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [loadProject, setloadProject] = useState(true);
  const [initialData, setInitialData] = useState(null);
  const [estadoAprobacion, setEstadoAprobacion] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesComponent, setImagesComponent] = useState(false);
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  useEffect(() => {
    getInitialData();
    if (id) getMessages();
  }, [id]);
  useEffect(() => {
    if (!initialData) {
      return;
    }
    if (initialData.fideicomiso) {
      if (initialData.fideicomiso.archivo) {
        getResourceFromUrl(Host + initialData.fideicomiso.archivo, setArchivo);
      }
      if (initialData.fideicomiso.fideicomiso_firmado) {
        getResourceFromUrl(
          Host + initialData.fideicomiso.fideicomiso_firmado,
          setFideicomisoFirmado
        );
      }
    }
    if (initialData.minuta_escrituracion) {
      if (initialData.minuta_escrituracion.archivo) {
        getResourceFromUrl(
          Host + initialData.minuta_escrituracion.archivo,
          setMinutaArchivo
        );
      }
    }
    if (initialData.nueva_escritura) {
      getResourceFromUrl(initialData.nueva_escritura, setNuevaEscritura);
    }
    if (initialData.nuevo_certificado_tradicion_libertad) {
      getResourceFromUrl(
        initialData.nuevo_certificado_tradicion_libertad,
        setNuevoCertificadoTradicionTibertad
      );
    }
  }, [initialData]);

  useEffect(() => {
    if (estadoAprobacion) {
      /* console.log("estadoAprobacion:---->:", estadoAprobacion); */
    }
  }, [estadoAprobacion]);
  const getMessages = async () => {
    return httpService(
      `/asset/list/feedback/messages/${id}/?limit=50&offset=0"`
    )
      .get()
      .then((res) => {
        
        if (res && res.results) {
          let messages = res.results;
          setMessages(messages.reverse());
        } else {
          setMessages([]);
        }

        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const newMessages = async () => {
    getMessages();
    getInitialData();
  };

  useEffect(() => {
    /* imgSlider(); */
  }, [images]);
  const getInitialData = useCallback(async () => {
    const url = `asset/retrieve/${id}/`;
    setLoader(true);
    return httpService(url)
      .get()
      .then(
        /* async */ (res) => {
          if (res instanceof Object) {
            if (res && res.id) {
              setInitialData(res);
              //showMessages
              if (textEstados[res.estado_aprobacion]) {
                const estado_aprobacion = res.estado_aprobacion;
                const _paso = textEstados[res.estado_aprobacion].paso;
                setPaso(_paso);
                
                if (
                  paso <= 2.1 &&
                  estado_aprobacion !== "ACTIVO_APROBADO_SPONSOR"
                ) {
                  setShowMessages(true);
                } else {
                  setShowMessages(false);
                }
              } else {
                setShowMessages(false);
              }

              if (
                res &&
                res.estado_aprobacion &&
                estadoAprobacion !== res.estado_aprobacion
              ) {
                /* alert("estadoAprobacion changed...") */
              }
              let _images = [];

              if (res.imagen_1) {
                _images.push(res.imagen_1);
              }
              if (res.imagen_2) {
                _images.push(res.imagen_2);
              }

              if (res.imagen_3) {
                _images.push(res.imagen_3);
              }
              if (res.imagen_4) {
                _images.push(res.imagen_4);
              }
              if (res.imagen_5) {
                _images.push(res.imagen_5);
              }
              setImages(_images);
              setloadProject(true);
            } else {
              console.log("Result empty array:", res);
            }
          }
          return res;
        }
      )
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        if (err && err.status === 404) {
          setloadProject(false);
        }
        return;
      })
      .finally(() => {
        setLoader(false);
      });
  });

  /*  const imgSlider = async () => {
    let component;
    let comp = "";
    new Promise((resolve) => {});
  }; */
  const renderThumbs = (item) => {
    //item[0]
    return [<div>1</div>, <div>2</div>];
  };
  const getNameOfUrl = (attr) => {
    if (!initialData) {
      return "N/A";
    }
    let url = initialData[attr];
    if (!url) {
      return "N/A";
    }

    let name = url.substring(url.lastIndexOf("/") + 1);
    return name;
  };
  const viewDocument = (attr) => {
    const url = initialData[attr];
    window.open(url, "_blank", "noreferrer");
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };
  const handleApprove = (approve) => {
    setOpenApprove(false);
    if (approve) {
      sendApprove();
    }
  };
  const handleOpenApprove = () => {
    setOpenApprove(true);
  };

  const handleCloseDecline = () => {
    setOpenDecline(false);
  };
  const handleDecline = (decline) => {
    setOpenDecline(false);
    if (decline) {
      setIsOpen(decline);
    }
  };
  const sendDecline = (decline) => {
    if (decline) {
      setIsOpen(decline);
      const texarea = document.getElementById("motivo");
      const documentFile = document.getElementById("document");
      
      const selectedFile = documentFile.files[0];
      if (texarea.value) {
        if (selectedFile) {
          const replacedName = selectedFile.name.replace(/[^a-z0-9.]/gi, "_");
          
          sendMessage(texarea.value, selectedFile);
        } else {
          sendMessage(texarea.value);
        }
      }
    }
  };
  const sendApprove = () => {
    const url = `/asset/send/approved/sponsor/${id}/`;
    return httpService(url)
      .patch()
      .then((res) => {
        if (res && res.id && res.estado_aprobacion) {
          if (estadoAprobacion != res.estado_aprobacion) {
            setEstadoAprobacion(res.estado_aprobacion);

            setOpen(true);
          }

          newMessages();
        } else {
          //setMessages([]);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const handleOpenDecline = () => {
    setOpenDecline(true);
  };
  const sendMessage = (message, file = null) => {
    let payload = new FormData();
    payload.append("activo", `${id}`);
    payload.append("mensaje", `${message}`);
    if (file) {
      const replacedName = file.name.replace(/[^a-z0-9.]/gi, "_");
      payload.append("archivo_adjunto", file, `${replacedName}`);
    }
    return httpService(`/asset/send/feedback/`, payload)
      .post()
      .then((res) => {
        
        if (res && res.activo) {
          //setMessages(res.results);
          getMessages();
          closeModal();
        } else {
          //setMessages([]);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const leave = () => {
    setOpen(false);
  };
  const openPdf = (file) => {
    window.open(file, "_blank", "noreferrer");
  };

  return (
    <div>
      {loadProject && (
        <div className="page-admin-asset-sponsor container-animated">
          <Dialog open={open} onClose={handleClose}>
            <div style={{ padding: "25px 30px" }}>
              <div
                className="w-100"
                style={{ minWidth: "350px", textAlign: "center" }}
              >
                <span className="text-subtitle-dark">
                  {initialData &&
                    (textEstados[initialData.estado_aprobacion]
                      ? textEstados[initialData.estado_aprobacion].nombre
                      : initialData.estado_aprobacion)}
                </span>{" "}
                {/* <span className="text-subtitle">{textDialog}</span>
              <span className="text-subtitle-dark">?</span> */}
              </div>
              <div className="w-100">
                <div className="btn-group mt-4 w-100 btn-group-logout-only">
                  <div className="btn-cancel">
                    <Boton
                      className={"btn _btn-primary font-14"}
                      style={{ borderRadius: "8px", width: "150px" }}
                      label={"Aceptar"}
                      handlerClick={leave}
                    />
                  </div>
                  {/* <div className="btn-logout">
                    <Boton
                      className={"btn _btn-primary font-14"}
                      style={{ borderRadius: "8px", width: "150px" }}
                      label={"Si, salir"}
                      handlerClick={leave}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog open={openApprove} onClose={handleCloseApprove}>
            <div style={{ padding: "25px 30px" }}>
              <div
                className="w-100"
                style={{ minWidth: "350px", textAlign: "center" }}
              >
                <span className="text-subtitle-dark">
                  ¿Desea aprobar el activo
                </span>
                <span className="text-subtitle"></span>
                <span className="text-subtitle-dark">?</span>
              </div>
              <div className="w-100">
                <div className="btn-group mt-4 w-100 btn-group-logout">
                  <div className="btn-cancel">
                    <Boton
                      className={"btn _btn-primary font-14"}
                      style={{ borderRadius: "8px", width: "150px" }}
                      label={"Continuar"}
                      handlerClick={() => handleApprove(true)}
                    />
                  </div>
                  <div className="btn-logout">
                    <Boton
                      className={"btn _btn-primary font-14"}
                      handlerClick={() => handleApprove(false)}
                      style={{
                        borderRadius: "8px",
                        width: "150px",
                        background: "white",
                        border: "1px solid #112e6d",
                        color: "#112e6d",
                      }}
                      label={"Cancelar"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
          <Dialog open={openDecline} onClose={handleCloseDecline}>
            <div style={{ padding: "25px 30px" }}>
              <div
                className="w-100"
                style={{ minWidth: "350px", textAlign: "center" }}
              >
                <span className="text-subtitle-dark">
                  ¿Desea rechazar el activo
                </span>
                <span className="text-subtitle"></span>
                <span className="text-subtitle-dark">?</span>
              </div>
              <div className="w-100">
                <div className="btn-group mt-4 w-100 btn-group-logout">
                  <div className="btn-cancel">
                    <Boton
                      className={"btn _btn-primary font-14"}
                      style={{ borderRadius: "8px", width: "150px" }}
                      label={"Continuar"}
                      handlerClick={() => handleDecline(true)}
                    />
                  </div>
                  <div className="btn-logout">
                    <Boton
                      className={"btn _btn-primary font-14"}
                      handlerClick={() => handleDecline(false)}
                      style={{
                        borderRadius: "8px",
                        width: "150px",
                        background: "white",
                        border: "1px solid #112e6d",
                        color: "#112e6d",
                      }}
                      label={"Cancelar"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Dialog>

          <LoaderComponent loader={loader} />
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <BreadcrumbsComponent params={id} />
            </div>
          </div>
          <div className="row mt-4 custom-col-1">
            <div className="col-md-12">
              <div className="card-container ">
                <div className="row custom w-100">
                  <div className="col-md-6 custom">
                    {images && (
                      <Carousel
                        autoPlay={true}
                        infiniteLoop={true}
                        selectedItem={images.length ? images.length : 0}
                        showArrows={
                          true
                        } /* onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb} */
                        autoFocus={true}

                        /* renderThumbs={"100px"} */
                        /* renderThumbs={renderThumbs} */
                      >
                        {/* <div><img src="https://plus.unsplash.com/premium_photo-1684107938987-0693da2a9b37?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" />
                        <p className="legend">Legend 1</p>
                      </div> */}
                        {images.map((img, i, arr) => {
                          if (i === arr.length - 1) {
                            // setImagesComponent(true)
                          }
                          return (
                            <div key={`img-${i}`}>
                              <img src={img} />
                              {/* <p className="legend">Legend 1</p> */}
                            </div>
                          );
                        })}
                      </Carousel>
                    )}
                  </div>
                  <div className="col-md-4 custom">
                    {initialData && (
                      <div className="w-100">
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ marginLeft: "-15px" }}
                            className="badge"
                          >
                            <BadgesProjects
                              labelStyle={{
                                background: initialData.tipo_proyecto.color,
                              }}
                              className={`${
                                classNameProyect[
                                  initialData.tipo_proyecto.nombre
                                ]
                                  ? classNameProyect[
                                      initialData.tipo_proyecto.nombre
                                    ]
                                  : "bg-blue"
                              }`}
                              text={initialData.tipo_proyecto.nombre}
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ marginLeft: "-15px" }}>
                            <div className="table-title blue">
                              {initialData.nombre}
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  Código:
                                </span>
                                <span> &nbsp;{initialData.codigo}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  Cédula catastral:
                                </span>
                                <span>
                                  {" "}
                                  &nbsp;{initialData.cedula_catastral}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  Matrícula inmobiliaria:
                                </span>
                                <span>
                                  {" "}
                                  &nbsp;{initialData.matricula_inmobiliaria}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  Canon arriendo:
                                </span>
                                <span>
                                  {" "}
                                  &nbsp;
                                  {numFormatter({
                                    num: initialData.canon_arriendo,
                                    decimals: 2,
                                  })}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  Valor de activo:
                                </span>
                                <span>
                                  {" "}
                                  &nbsp;
                                  {numFormatter({
                                    num: initialData.valor_activo,
                                    decimals: 2,
                                  })}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  Estado:
                                </span>
                                <span
                                  className="status-style"
                                  style={{
                                    background: textEstados[
                                      initialData.estado_aprobacion
                                    ]
                                      ? textEstados[
                                          initialData.estado_aprobacion
                                        ].color
                                      : "",
                                    color: textEstados[
                                      initialData.estado_aprobacion
                                    ]
                                      ? InvertColors(
                                          textEstados[
                                            initialData.estado_aprobacion
                                          ].color
                                        )
                                      : "black",
                                  }}
                                >
                                  {textEstados[initialData.estado_aprobacion]
                                    ? textEstados[initialData.estado_aprobacion]
                                        .nombre
                                    : initialData.estado_aprobacion}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={
                showMessages ? "col-sm-6 col-md-8" : "col-sm-6 col-md-12"
              }
            >
              <div className="card-container mt-4">
                <div className="row w-100">
                  <div className="col-md-12">
                    <div
                      className="page-security-head"
                      style={{ padding: "5px 0px 0px" }}
                    >
                      <div className="page-security-subtitle">
                        Sobre el proyecto
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12 mt-4">
                    <Tabs
                      defaultActiveKey="informacion-proyecto"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab
                        eventKey="informacion-proyecto"
                        title="Información del proyecto"
                      >
                        <div className="tab-container">
                          <div style={{ display: "flex" }}>
                            <div
                              style={{ display: "block" }}
                              className="row custom w-100"
                            >
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="información del arrendatario" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.informacion_arrendatario}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Categoría" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.categoria.nombre}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Arrendatario" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.nombre_arrendatario}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Información del arrendatario" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.informacion_arrendatario}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Año de construcción" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.ano_construccion}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Dimensión m²" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.dimension_m2}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Área construida m²" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.area_construida_m2}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Área gla m²" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.area_gla_m2}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Área lote m²" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.area_lote_m2}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Área vendible m²" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.area_vendible_m2}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Vencimiento de contrato de arrendamiento" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.vencimiento_contrato_arrendamiento}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Certificado de tradición de libertad" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      getNameOfUrl(
                                        "certificado_tradicion_libertad"
                                      )}
                                  </div>
                                  <div
                                    style={{
                                      width: "30px",
                                      margin: "auto 0 auto auto",
                                      display: "flex",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewDocument(
                                        "certificado_tradicion_libertad"
                                      )
                                    }
                                  >
                                    <IconBoxEye
                                      width="49px"
                                      height="23px"
                                      colorIcon="#9891bb"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Contrato de arriendo" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      getNameOfUrl("contrato_arriendo")}
                                  </div>
                                  <div
                                    style={{
                                      width: "30px",
                                      margin: "auto 0 auto auto",
                                      display: "flex",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewDocument("contrato_arriendo")
                                    }
                                  >
                                    <IconBoxEye
                                      width="49px"
                                      height="23px"
                                      colorIcon="#9891bb"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-12     mb-4"
                              >
                                <FormFloatingLabel label="Descripción" />
                                <div
                                  style={{ float: "left" }}
                                  className="custom-input-box-tarea"
                                >
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.descripcion_proyecto}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="País" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.pais.name}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Estado/Región" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.region.name}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Ciudad" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.ciudad.name}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Dirección" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.direccion}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Descripción de ubicación" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.descripcion_ubicacion}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Latitud" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.latitud}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Longitud" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData && initialData.longitud}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ float: "left" }}
                                className="col-md-6 mb-4"
                              >
                                <FormFloatingLabel label="Sponsor" />
                                <div className="custom-input-box-tarea">
                                  <div className="text-read-only">
                                    {initialData &&
                                      initialData.sponsor.company_name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      {paso >= 5 && initialData && (
                        <Tab eventKey="fideicomiso" title="Fideicomiso">
                          <div className="row">
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Devise"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.fideicomiso.aprobado_devise
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Fiducia"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.fideicomiso.aprobado_fiducia
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Propietario"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.fideicomiso
                                      .aprobado_propietario
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Sponsor"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.fideicomiso.aprobado_sponsor
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Archivo"}
                                  required={false}
                                />
                                <div
                                  style={{ display: "block" }}
                                  className="custom-input-box-tarea"
                                >
                                  <div className="box-info-data overflow-hidden">
                                    {/* {initialData.fideicomiso.archivo.split("/")} */}

                                    {/* {archivo && (
                                      <iframe
                                        src={archivo}
                                        width="100%"
                                        height="300px" //data:application/pdf;base64,
                                      />
                                    )} */}
                                    {/* {archivo && <Document  file={archivo} />} */}
                                  </div>
                                  {/* <div>
                                    <Boton
                                      className={"btn _btn-secondary font-14"}
                                      style={{
                                        borderRadius: "8px",
                                        width: "150px",
                                      }}
                                      label={"Descargar"}
                                      handlerClick={() => openPdf(archivo)}
                                    />
                                  </div> */}
                                  {initialData &&
                                      initialData.fideicomiso &&
                                      initialData.fideicomiso.archivo && (
                                        <div style={{ marginTop: "10px" }}>
                                          <PdfViewer
                                            url={
                                              Host +
                                              initialData.fideicomiso.archivo
                                            }
                                          />
                                        </div>
                                      )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Fideicomiso Firmado"}
                                  required={false}
                                />
                                <div
                                  style={{ display: "block" }}
                                  className="custom-input-box-tarea"
                                >
                                  <div className="box-info-data overflow-hidden">
                                    {/* {initialData.fideicomiso.fideicomiso_firmado.split("/")} */}
                                    {/* {fideicomisoFirmado && (
                                      <iframe
                                        src={fideicomisoFirmado}
                                        width="100%"
                                        height="300px"
                                      />
                                    )} */}
                                    {/* <div>
                                      <Boton
                                        className={"btn _btn-secondary font-14"}
                                        style={{
                                          borderRadius: "8px",
                                          width: "150px",
                                        }}
                                        label={"Descargar"}
                                        handlerClick={() =>
                                          openPdf(fideicomisoFirmado)
                                        }
                                      />
                                    </div> */}
                                    {initialData &&
                                      initialData.fideicomiso &&
                                      initialData.fideicomiso.fideicomiso_firmado && (
                                        <div style={{ marginTop: "10px" }}>
                                          <PdfViewer
                                            url={
                                              Host +
                                              initialData.fideicomiso.fideicomiso_firmado
                                            }
                                          />
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      )}
                      {paso >= 7 && initialData && (
                        <Tab eventKey="minuta" title="Minuta de Escrituración">
                          <div className="row">
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Fiducia"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.minuta_escrituracion
                                      .aprobado_fiducia
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Notario"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.minuta_escrituracion
                                      .aprobado_notario
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Propietario"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.minuta_escrituracion
                                      .aprobado_propietario
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Aprobado Sponsor"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.minuta_escrituracion
                                      .aprobado_sponsor
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Minuta Aprobada"}
                                  required={false}
                                />
                                <div className="custom-input-box-tarea">
                                  <div className="box-info-data">
                                    {initialData.minuta_escrituracion
                                      .minuta_aprobada
                                      ? "Si"
                                      : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={"Archivo"}
                                  required={false}
                                />
                                <div
                                  style={{ display: "block" }}
                                  className="custom-input-box-tarea"
                                >
                                  <div className="box-info-data overflow-hidden">
                                    {/* {initialData.minuta_escrituracion.archivo.split("/")} */}
                                    {/* {minutaArchivo && (
                                      <iframe
                                        src={minutaArchivo}
                                        width="100%"
                                        height="300px"
                                      />
                                    )} */}
                                  </div>
                                  {/* <div>
                                    <Boton
                                      className={"btn _btn-secondary font-14"}
                                      style={{
                                        borderRadius: "8px",
                                        width: "150px",
                                      }}
                                      label={"Descargar"}
                                      handlerClick={() =>
                                        openPdf(minutaArchivo)
                                      }
                                    />
                                  </div> */}
                                  {initialData &&
                                      initialData.minuta_escrituracion &&
                                      initialData.minuta_escrituracion.archivo && (
                                        <div style={{ marginTop: "10px" }}>
                                          <PdfViewer
                                            url={
                                              Host +
                                              initialData.minuta_escrituracion.archivo
                                            }
                                          />
                                        </div>
                                      )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                      )}
                      {paso >= 9 &&
                        initialData &&
                        (initialData.nueva_escritura ||
                          initialData.nuevo_certificado_tradicion_libertad) && (
                          <Tab eventKey="nueva_doc" title="Nueva Documentación">
                            <div className="row">
                              {nuevaEscritura && (
                                <div className="col-md-12 mb-4">
                                  <div className="form-custom-box">
                                    <FormFloatingLabel
                                      label={"Nueva escritura"}
                                      required={false}
                                    />
                                    <div
                                      style={{ display: "block" }}
                                      className="custom-input-box-tarea"
                                    >
                                      {/* <div className="box-info-data overflow-hidden">
                                        {nuevaEscritura && (
                                          <iframe
                                            src={nuevaEscritura}
                                            width="100%"
                                            height="300px"
                                          />
                                        )}
                                      </div> */}
                                      {/* <div>
                                        <Boton
                                          className={
                                            "btn _btn-secondary font-14"
                                          }
                                          style={{
                                            borderRadius: "8px",
                                            width: "150px",
                                          }}
                                          label={"Descargar"}
                                          handlerClick={() =>
                                            openPdf(nuevaEscritura)
                                          }
                                        />
                                      </div> */}
                                      {initialData &&
                                      initialData.nueva_escritura &&
                                       (
                                        <div style={{ marginTop: "10px" }}>
                                          <PdfViewer
                                            url={
                                             
                                              initialData.nueva_escritura
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {nuevoCertificadoTradicionTibertad && (
                                <div className="col-md-12 mb-4">
                                  <div className="form-custom-box">
                                    <FormFloatingLabel
                                      label={
                                        "Nuevo certificado de tradicion y libertad"
                                      }
                                      required={false}
                                    />
                                    <div
                                      style={{ display: "block" }}
                                      className="custom-input-box-tarea"
                                    >
                                      <div className="box-info-data overflow-hidden">
                                        {/* {initialData.minuta_escrituracion.archivo.split("/")} */}
                                        {/* {nuevoCertificadoTradicionTibertad && (
                                          <iframe
                                            src={
                                              nuevoCertificadoTradicionTibertad
                                            }
                                            width="100%"
                                            height="300px"
                                          />
                                        )} */}
                                      </div>
                                      {/* <div>
                                        <Boton
                                          className={
                                            "btn _btn-secondary font-14"
                                          }
                                          style={{
                                            borderRadius: "8px",
                                            width: "150px",
                                          }}
                                          label={"Descargar"}
                                          handlerClick={() =>
                                            openPdf(
                                              nuevoCertificadoTradicionTibertad
                                            )
                                          }
                                        />
                                      </div> */}
                                      {initialData &&
                                      initialData.nuevo_certificado_tradicion_libertad &&
                                       (
                                        <div style={{ marginTop: "10px" }}>
                                          <PdfViewer
                                            url={
                                             
                                              initialData.nuevo_certificado_tradicion_libertad
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Tab>
                        )}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {showMessages && (
              <div className="col-sm-6 col-md-4 mt-30 ">
                <div className="card-custom">
                  <Chat messages={messages} id={id} />
                  <Boton
                    className={"btn _btn-primary font-14"}
                    handlerClick={newMessages}
                    icon={<IconBoxReloadOutLine colorIcon="#112e6d" />}
                    style={{
                      borderRadius: "20px",
                      width: "auto",
                      background: "white",
                      border: "1px solid #112e6d",
                      color: "#112e6d",
                      alignSelf: "center",
                    }}
                    label={""}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: "10px",
                    }}
                  >
                    {paso <= 7 &&
                      <>
                        <div className="form-btn-group-left col-sm-6">
                          <div className="form-btn-box">
                            <Boton
                              className={"btn _btn-primary font-14"}
                              label="Aprobar"
                              borderRadius="10px"
                              handlerClick={handleOpenApprove}
                            />
                          </div>
                        </div>
                        <div className="form-btn-group-rigth col-sm-6">
                          <div className="form-btn-box">
                            <Boton
                              className={"btn _btn-danger font-14"}
                              label="Rechazar"
                              borderRadius="10px"
                              handlerClick={handleOpenDecline}
                            />
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {!loadProject && <Page404 isdelay={false} />}
      <Modal
        id="decline"
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("root")}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Motivo</h2>

        <div>
          <textarea
            /* onKeyDown={(e) => onSubmitDecline(e)} */
            type="text"
            id="motivo"
            className="form-control"
            placeholder="Motivo"
          />
          <input type="file" id="document" className="form-control" />

          <div className="btn-group mt-4 w-100 btn-group-logout">
            <div className="btn-cancel">
              <Boton
                className={"btn _btn-primary font-14"}
                style={{ borderRadius: "8px", width: "150px" }}
                label={"Continuar"}
                handlerClick={() => sendDecline(true)}
              />
            </div>
            <div className="btn-logout">
              <Boton
                className={"btn _btn-primary font-14"}
                handlerClick={closeModal}
                style={{
                  borderRadius: "8px",
                  width: "150px",
                  background: "white",
                  border: "1px solid #112e6d",
                  color: "#112e6d",
                }}
                label={"Cancelar"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
