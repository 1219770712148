import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import "./CardBienvenida.css";

export const CardBienvenida = (props) => {
  const navigate = useNavigate();

  const invertir = () => {
    navigate("/invertir");
  }
  return (
    <div className="cardBienvenida">
      <div className="textBox">
        <h3 className="tile">Hola bienvenido <strong>👋</strong></h3>
        <h2 className="name">{(props.userData?props.userData.user.name:'')}</h2>
        <p className="paragraph">Invertir en bienes raíces nunca antes fue tan fácil y seguro con Devise.
        </p>
      </div>
      <div >
        <Button className="button btn" handleClick={() => invertir()} redirect="/invertir" >Quiero invertir</Button>
      </div>
    </div>
  );
};
