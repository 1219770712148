// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f
import React, { useEffect, useState } from "react";
import styles from "./MenuBlog.module.css";
import "./MenuBlog.css";

const MenuBlog = ({ buttons, setCode }) => {
  
  const [buttonList, setButtonList] = useState([]);
  const [CODE, setCODE] = useState(
    ""
  );
  const initButton = (btn) => {
    setButtonList(btn);
  };
  useEffect(() => {
    let code = buttons && buttons.length > 0 ? buttons[0].code : "";
    setCode(code);
    setCODE(code);
    initButton(buttons);
  }, []);
  useEffect(() => {
    console.log("CODE:", CODE);
  }, [CODE]);
  

  const clickButton = (code) => {
    let btnTemp = [...buttonList];
    btnTemp.forEach((item, i, arr) => {
      if (item.code === code) {
        setCode(code);
        item.active = true;
      } else {
        item.active = false;
      }
      if (i === arr.length - 1) {
        /* setTimeout( ()=> {
          setButtonList(btnList);
        },1000); */
        setButtonList(btnTemp);
      }
    });
  };
  const renderButtons = () => {
    let codeS = "todo";

    let btnList = [];
    if (buttonList.length) {
      let selected = buttonList.filter((item) => item.active);
      codeS = selected[0].code;
      let topBtn = buttonList.filter((item) => item.code === codeS);
      let topBtnRemoved = buttonList.filter((item) => item.code !== codeS);
      let _btnList = topBtn[0];
      btnList = [_btnList, ...topBtnRemoved];
    }

    return btnList.map((item, i, arr) => (
      <div
        key={"key-" + item.code + "-" + i}
        onClick={() => clickButton(item.code)}
        className={
          "item-menu" + (item.active ? " item-active-" + item.code : "")
        }
      >
        {item.active && (
          <img
            alt=""
            className="vector"
            src="https://static.overlay-tech.com/assets/92500225-3840-4871-aab3-78d39916b049.svg"
          />
        )}
        <p className={styles.label}>{item.text}</p>
      </div>
    ));
  };
  return <div className={styles.menuBlog}>{renderButtons()}</div>;
};

export default MenuBlog;
