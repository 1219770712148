import { useForm } from "react-hook-form";
import FormFloatingLabel from "../FormFloatingLabel";
import Boton from "../Boton";
import Logo from "../../assets/Logo/Logo";
import "./StayInformed.css";
import React, { forwardRef, useRef, useState } from "react";
import { toast } from "react-toastify";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { grecapchaWebSite } from "../../constants/global";
import { UseReCaptchaV3 } from "../UseReCaptchaV3/UseReCaptchaV3";

export const StayInformed = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [sendBtn, setSendBtn] = useState(false);
  const onSubmit = async (data) => {
    const id = toast.loading("Enviando...", { className: "foo-bar" });
    let token = "";

    if (_reCaptcha.current) {
      // Llamar a la función en el componente hijo
      token = await _reCaptcha.current.handleReCaptchaVerify();
      console.log("token-------->",token)
    }
    if(!token){
      let message = "¡Token inálido!";
      toast.update(id, {
        render: message,
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        className: "foo-bar",
      });
      return
    }
    data['token'] = token
   
    console.log("onSubmit:", data);
    setSendBtn(true);
    try {
      let response = await saveData(data);
      if ( response && response.email && response.names) {
        toast.update(id, {
          render: "Subscripción exitosa",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          className: "foo-bar",
        });
        reset();
      } else {
        let message = "Ocurrió un error, inténtelo más tarde"
        console.log("ERROR----->",response)
        if(response && response.data && response.data.errors){
          message = response.data.errors[0].detail
        }
        toast.update(id, {
          render: message,
          type: "warning",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          className: "foo-bar",
        });
      }
      setSendBtn(false);
      
    } catch (error) {
      console.log("ERROR----->",error)
      setSendBtn(true);
      toast.update(id, {
        render: "Ocurrió un error, inténtelo más tarde, 500",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        className: "foo-bar",
      });
    }
  };
  const saveData = async (payload) => {
    return httpService(`/stay_informed/api/create/`, payload)
      .post()
      .then((res) => {
        console.log("saveData------------------>", res);
        return res
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return err;
      });
  };


  const _reCaptcha = useRef();

  const callbackReCaptcha = async () => {
    
    if (_reCaptcha.current) {
     
      // Llamar a la función en el componente hijo
      let token = await _reCaptcha.current.handleReCaptchaVerify();
      console.log("token-------->",token)
    }
  }

  return (
    <div className="w-100">
      <GoogleReCaptchaProvider
        reCaptchaKey={grecapchaWebSite}
        language="es"
        
        
      >
        <div className="row">
        <div className="mb-4 logo-s-info">
          <div className="menu-text">
            <Logo width="75%" height="80%" />
          </div>
        </div>
        <div className="col-md-9 mb-4">
          <div className="text-small primary s-info">
            Suscríbete y podrás recibir información de cómo invertir en
            proyectos inmobiliarios
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <form
          style={{ display: "block" }}
          className="row"
          onSubmit={handleSubmit((data) => onSubmit(data))}
        >
          <div style={{ display: "inline-block" }} className="col-md-4 mb-3">
            <div className="form-custom-box">
              <FormFloatingLabel label="Nombre" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("names", { required: true })}
                  autoComplete="off"
                  readOnly={sendBtn}
                />
              </div>
              {errors.names && (
                <label
                  style={{ position: "absolute", left: "20px" }}
                  className="form-error"
                >
                  Este campo es requerido
                </label>
              )}
            </div>
          </div>
          <div style={{ display: "inline-block" }} className="col-md-4 mb-3">
            <div className="form-custom-box">
              <FormFloatingLabel label="Correo" />
              <div className="custom-input-box">
                <input
                  type="email"
                  {...register("email", { required: true })}
                  autoComplete="off"
                  readOnly={sendBtn}
                />
              </div>
              {errors.email && (
                <label
                  style={{ position: "absolute", left: "20px" }}
                  className="form-error"
                >
                  Este campo es requerido
                </label>
              )}
            </div>
          </div>
          <div
            style={{ minWidth: "fit-content", display: "inline-block" }}
            className="col-md-1 mb-3"
          >
            <Boton
              disabled={sendBtn}
              className={"btn _btn-primary font-14"}
              label={"Registrar"}
            />
          </div>
        </form>

        {_reCaptcha && <UseReCaptchaV3 ref={_reCaptcha} />}
        
      </div>
      </GoogleReCaptchaProvider>
      
    </div>
  );
};
