import { useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";

export default function PageProjectDetail() {
  const { id } = useParams();
  
  return (
    <div className="page-referals container-animated">
      PageProjectDetail - Detalles del Proyecto
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent params={id} />
        </div>
      </div>
    </div>
  );
}
