import React from "react";
import "./CardBlog.styles.css";

export const CardBlog = (props) => {
  return (
    <div className="cardsBlog" style={ props.bg_color?{ background: props.bg_color}:{} }>
      <div className="boxContent">
        <div className="textContent">
          <p className="title">{props.content1 ? props.content1 : ""}</p>

          {props.content2}

          <div className="description">{props.content3 ? props.content3 : ""}</div>
        </div>
        <img
          alt={props.content1}
          className="content"
          src={
            props.image
              ? props.image
              : "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png"
          }
        />
      </div>
    </div>
  );
};
