import React from "react";
import "./Button.css";

export const Button = (props) => {
  return (
    <div>
      <button className={props.className} onClick={(props && props.handleClick?props.handleClick:() => console.log())}>
        <span className="label" href={props.redirect}>
          {props.children}
        </span>
      </button>
    </div>
  );
};
