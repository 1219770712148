import { useEffect, useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import { CardBlog } from "../../components/CardBlog/CardBlog";
import MenuBlog from "../../components/CardMenuBlog/CardMenuBlog";
import { CategoryTag } from "../../components/CategoryTag/CategoryTag";
import './PageAcademy.css';

export default function PageAcademy() {
  const [CODE, setCODE] = useState("");
  const setCode = (code) => {
    setCODE(code);
  };
  useEffect(() => {
    console.log("PageAcademy:", CODE);
  }, [CODE]);

  const buttons = [
    {
      code: "todo",
      text: "Todo",
      active: true,
      carbBlog: [
        {
          item1: "Libertad Financiera, claves para 2023",
          item2: <CategoryTag className="categoryTag" text="Mis finanzas" bg="category-tag-color-3" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Beneficios de invertir en el sector en el 2023",
          item2: <CategoryTag className="categoryTag" text="Mi Sector" bg="category-tag-color-4" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Recarga tu cuenata con PSE fácil",
          item2: <CategoryTag className="categoryTag" text="Devise Lover" bg="category-tag-color-2" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Comenzando en el mundo de las inversiones",
          item2: <CategoryTag className="categoryTag" text="Mis finanzas" bg="category-tag-color-3" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Nuevos proyectos de inversión",
          item2: <CategoryTag className="categoryTag" text="Mi Sector" bg="category-tag-color-4" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Crecimiento del sector para 2023",
          item2: <CategoryTag className="categoryTag" text="Mi Sector" bg="category-tag-color-4" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "¿Qué son los Devide Coin?",
          item2: <CategoryTag className="categoryTag" text="Devise Lover" bg="category-tag-color-2" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
    {
      code: "devise-lover",
      text: "Devise Lover",
      active: false,
      carbBlog: [
        {
          item1: "Recarga tu Cuenta con PSE fácilmante",
          item2: <CategoryTag className="categoryTag" text="Devise Lover" bg="category-tag-color-2" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "¿Qué son los Devide Coin?",
          item2: <CategoryTag className="categoryTag" text="Devise Lover" bg="category-tag-color-2" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
    {
      code: "mis-finanzas",
      text: "Mis Finanzas",
      active: false,
      carbBlog: [
        {
          item1: "Libertad Financiera, claves para 2023",
          item2: <CategoryTag className="categoryTag" text="Mis Finanzas" bg="category-tag-color-3" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Comenzando en el mundo de las inversiones",
          item2: <CategoryTag className="categoryTag" text="Mis finanzas" bg="category-tag-color-3" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
    {
      code: "mi-sector",
      text: "Mi Sector",
      active: false,
      carbBlog: [
        {
          item1: "Beneficis de invertir en el sector en el 2023",
          item2: <CategoryTag className="categoryTag" text="Mi Sector" bg="category-tag-color-4" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Nuevos proyectos de inversión",
          item2: <CategoryTag className="categoryTag" text="Mi Sector" bg="category-tag-color-4" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Crecimiento del sector para 2023",
          item2: <CategoryTag className="categoryTag" text="Mi Sector" bg="category-tag-color-4" />,
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
  ];

  const showCards = () => {
    if (CODE) {
      let cards = buttons.filter((item) => item.code === CODE)[0];
      let cardBlog = [...cards.carbBlog];
      return (
        <div className="row">
          {cardBlog.map((item, i) => {
            return (
              <div
                key={"card-blog-" + i}
                className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-custom-2 mb-4 container-animated"
              >
                <div className="card-blog-center">
                  <CardBlog
                    content1={item.item1}
                    content2={
                      item.item2
                    }
                    content3="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    image={item.image}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div className="page-academy container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-lg-3">
          <MenuBlog buttons={buttons} setCode={setCode} />
          {/* <CardBlog
            content1="Liberta Financiera, claves para 2023"
            image=""
            content2={
              <CategoryTag className="categoryTag" text="Mis Finanzas" />
            }
            content3="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          /> */}
        </div>
        <div className="col-md-9 col-lg-9">{showCards()}</div>
      </div>
    </div>
  );
}
