// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React from "react";
import {
  IconBoxBriefcase,
  IconBoxGraph,
  IconBoxSaving,
  IconHelpSupport,
} from "../Icons/Icons";

import "./CardBalance.styles.css";

export const CardBalance = (props) => {
  return (
    <div className="cardBalance">
      <div className="information">
        <p className="titleCard">
          {props.title}{" "}
          <span className="title2">
            {props.title2 ? "(" + props.title2 + ")" : ""}
          </span>
        </p>
        <div className="information">
          {props.saldocop && (
            <label className="saldocop">
              {props.saldocop}
              <strong className="saldocopEmphasis1"> </strong>
              <strong className="saldocopEmphasis2">COP</strong>
            </label>
          )}
          {props.rendimientos && (
            <label className="saldocop-medium">
              {props.rendimientoTitle}
              <div className="saldocop-small">{props.rendimientos}</div>
            </label>
          )}
          {props.rendimientos && <label className="saldoDeviseCoin">
            
            <strong className="saldoDeviseCoinEmphasis1"> {props.rendimientosSubTitle}</strong>
          </label>}

          {props.saldodevisecoin && <label className="saldoDeviseCoin">
            {props.saldodevisecoin}
            <strong className="saldoDeviseCoinEmphasis1"> Devise Coin</strong>
          </label>}
        </div>
      </div>
      <div className="iconBalanceTwo">
        {/* <IconBoxBriefcase height="66px" width="60px" /> */}
        {iconList(props.icon)}
      </div>
    </div>
  );
};
const iconList = (
  iconName,
  color = "#B9DE2C",
  width = "100%",
  height = "100%"
) => {
  let ICON = "icon";
  switch (iconName) {
    case "IconBoxBriefcase":
      ICON = <IconBoxBriefcase colorIcon={color} width="100%" height="100%" />;
      break;
    case "IconBoxSaving":
      ICON = <IconBoxSaving colorIcon={color} width="100%" height="100%" />;
      break;
    case "IconBoxGraph":
      ICON = <IconBoxGraph colorIcon={color} width="100%" height="100%" />;
      break;

    default:
      ICON = <IconHelpSupport colorIcon={color} width="40px" height="40px" />;
      break;
  }
  return ICON;
};
