import { createRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Logo from "../../assets/Logo/Logo";
import Boton from "../Boton";
import imagen from "../../assets/img2.jpeg";
import FormFloatingLabel from "../FormFloatingLabel";
import { Checkbox, containerClasses } from "@mui/material";
import "./SignupForm.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IconBoxEmail,
  IconBoxEye,
  IconBoxEyeOff,
  IconReferals,
  IconSecurity,
} from "../Icons/Icons";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { signupForm } from "../modelForms/signupForm";
import { rolesTypes } from "../../helpers/functions";

export default function SignupForm({ TYPE, codeRef }) {
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const [referredByCode, setReferredByCode] = useState(null);
  const [disableBTN, setDisableBTN] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesCodePhone, setCountriesCodePhone] = useState(null);

  useEffect(() => {
    if (codeRef) {
      const patron = signupForm["referred_by_code"].validators.pattern.value;
      if (
        patron.test(codeRef) &&
        codeRef.length <=
          signupForm["referred_by_code"].validators.maxLength.value
      ) {
        setReferredByCode(codeRef);
        checkCodeReferalOnInit(codeRef);
      } else {
        toast("Formato de código inválido", {
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          className: "foo-bar",
        });
      }
    }
  }, [codeRef]);

  useEffect(() => {
    if (referredByCode) {
      setDisableBTN(true);
    } else {
      setDisableBTN(false);
    }
  }, [referredByCode]);

  const [groups, setGroups] = useState(null);
  const _TYPES = rolesTypes()

  const setDataForm = {
    password: {},
    email: {},
    phone: {
      combine: "code_phone",
      prefix: "+",
    },
    code: {},
    groups: {},
    role: {},
    referred_by_code: {},
  };

  const getCountries = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCountries(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const setCodePhone = (value) => {
    console.log("setCodePhone:", value.target.value);
    onBlurInputs(value)
    setCountriesCodePhone(value.target.value);
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    if (confirmPasswordInput && confirmPasswordInput === evnt.target.value) {
      setError("re_password", null);
    } else if (confirmPasswordInput) {
      const formError = {
        type: "validate",
        message: "Las contraseñas no coinciden",
      };
      setError("re_password", formError);
    }
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const handleConfirmPasswordChange = (evnt) => {
    if (passwordInput === evnt.target.value) {
      setError("re_password", null);
    } else if (confirmPasswordInput) {
      const formError = {
        type: "validate",
        message: "Las contraseñas no coinciden",
      };
      setError("re_password", formError);
    }
    setConfirmPasswordInput(evnt.target.value);
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const [type, setType] = useState("");
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    trigger,
    formState: { errors, touchedFields },
  } = useForm();
  useEffect(() => {
    setRole(_TYPES[TYPE.toUpperCase()].id);
    setGroups(_TYPES[TYPE.toUpperCase()].id);
    const height = document.getElementsByClassName("column-rigth")[0];
    const element = document.getElementsByClassName("login-img")[0];
    getCountries();
    new Promise((resolve) =>
      setTimeout(() => {
        element.style.height = height.clientHeight + "px";
        resolve(true);
      }, 10)
    );

    function handleResize() {
      let height = document.getElementsByClassName("column-rigth")[0];
      if (height) {
        //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);
  const dataOption = (tilte, subtitle) => {
    setStyle(tilte);
    return (
      <div>
        <div className="style-title">{setStyle(tilte)}</div>
        <div className="style-sub-title">{subtitle}</div>
      </div>
    );
  };
  const setStyle = (text) => {
    let partsText = text.split(" ");
    let t2 = partsText.pop();
    let t1 = partsText;
    return (
      <>
        <span className="color-tertiary">{t1.join(" ")} </span>
        <span className="color-main">{t2} </span>
      </>
    );
  };
  const selectType = (type) => {
    setType(type);
  };

  const onChangeCodeReferal = (value) => {
    touchedFields["referred_by_code"] = true;
    console.log(touchedFields["referred_by_code"]);
    setReferredByCode(value.target.value);
  };

  const checkCodeReferalOnInit = (value) => {
    if (!value) {
      return;
    }
    const id = toast.loading("Verificando código de referido", {
      closeOnClick: true,
      className: "foo-bar",
    });

    const url = `/user/verify/referred/${value}/code/`;
    return httpService(url)
      .get()
      .then(async (res) => {
        console.log("checkCodeReferal response:", res);
        if (res && res instanceof Object) {
          setDisableBTN(!res.found);
          if (!res.found) {
            toast.update(id, {
              render: "El código no existe",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          } else {
            toast.update(id, {
              render: "El código es válido",
              type: "success",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
          toast.update(id, {
            render: "Error",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
        }

        return;
      })
      .finally(() => {});
  };

  const onBlurInputs = (event) => {
    if(event && event.target){
      console.log("onBlurInputs:",event.target.name);
      trigger(event.target.name);
    }
  }


  const checkCodeReferal = (value) => {
    onBlurInputs(value);
    if (!value.target.value) {
      return;
    }
    const patron = signupForm["referred_by_code"].validators.pattern.value;
    if (
      !patron.test(value.target.value) ||
      value.target.value.length >
        signupForm["referred_by_code"].validators.maxLength.value
    ) {
      return;
    }
    const id = toast.loading("Verificando código de referido", {
      closeOnClick: true,
      className: "foo-bar",
    });

    const url = `/user/verify/referred/${value.target.value}/code/`;
    return httpService(url)
      .get()
      .then(async (res) => {
        console.log("checkCodeReferal response:", res);
        if (res && res instanceof Object) {
          setDisableBTN(!res.found);
          if (!res.found) {
            toast.update(id, {
              render: "El código no existe",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          } else {
            toast.update(id, {
              render: "El código es válido",
              type: "success",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
          toast.update(id, {
            render: "Error",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
        }

        return;
      })
      .finally(() => {});
  };

  const onSubmit = async (data) => {
    const id = toast.loading("Se esta creando la cuenta...", {
      closeOnClick: true,
      className: "foo-bar",
    });
    console.log("TYPE:", TYPE);
    data["groups"] = groups;
    data["role"] = role;
    if (referredByCode) {
      data["referred_by_code"] = referredByCode;
    }
    let newData = {};
    for (let key in setDataForm) {
      if (data[key]) {
        if (setDataForm[key].combine) {
          newData[key] =
            (setDataForm[key].prefix ? setDataForm[key].prefix : "") +
            data[setDataForm[key].combine] +
            '*'+
            data[key];
        } else {
          newData[key] = data[key];
        }
      }
    }
    const formData = new FormData();
    for (let key in newData) {
      if (data[key] instanceof FileList) {
        const _file = data[key][0];
        console.log("Name:", _file.name);
        let name = _file.name.replace(/[^a-zA-Z0-9._ ]/g, "");
        name = name.replace(/\s{2,}/g, " ");
        name = name.replace(/ /g, "_");
        name = name.trim();
        console.log("Name clear:", name);
        formData.append(key, _file, name);
      } else {
        formData.append(key, newData[key]);
      }
    }
    let result = await saveData(formData);
    console.log("Result:", result);
    if (result && !(result.data && result.data.errors)) {
      toast.update(id, {
        render: "Cuenta creada",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      //window.location.replace('/register/cuenta-creada');
      navigate("/register/cuenta-creada", { replace: true });
    } else {
      if (result.data && result.data.errors) {
        let message = ``;
        let errors = [];
        errors = result.data.errors;
        errors.forEach((data) => {
          message += data.detail + `\n\r`;
        });
        toast.update(id, {
          render: message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
      } else
        toast.update(id, {
          render: "Ocurrió un error inténtelo más tarde",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
    }
  };
  const saveData = (formData) => {
    const url = "/user/";
    return httpService(url, formData)
      .post()
      .then(async (res) => {
        console.log("saveData:", res);
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return err;
      });
  };
  const re_passwordValidate = (value) => {
    return value === getValues("password");
  };
  return (
    <div className="register-container">
      <div className="container">
        <div className="column-rigth box">
          <div className="for">
            <div className="logo-img">
              <Logo />
            </div>

            <div className="form-title three text-field-box form-box">
              <h1 className="form-title-sup">
                <span className="color-tertiary">Quiero registrarme como </span>
                <span className="color-main">
                  {_TYPES[TYPE.toUpperCase()].label}
                </span>
              </h1>
              <div className="form-title-sub">
                Por favor ingresa los siguientes datos
              </div>
            </div>
            <div className="box-button-types">
              <div className="form-title three text-field-box form-box">
                <div className="w-100">
                  <form
                    className="row"
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                  >
                    <div className="col-md-12 mb-3">
                      <div className="form-custom-box">
                        <FormFloatingLabel
                          label={signupForm["email"].label}
                          required={
                            signupForm["email"].validators.required.value
                          }
                        />
                        <div className="custom-input-box">
                          <IconBoxEmail
                            width="25px"
                            height="14px"
                            colorIcon="#9891bb"
                          />
                          <input
                            type="text"
                            {...register(signupForm["email"].name, {
                              ...signupForm["email"].validators,
                              onBlur:(event)=>onBlurInputs(event)
                            })}
                            placeholder={signupForm["email"].placeholder}
                            autoComplete="off"
                          />
                        </div>

                        {errors.email && (
                          <div>
                            <label className="form-error">
                              {errors["email"].message}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="_form-group">
                      <div className="col-md-4 pr-0">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={signupForm["code_phone"].label}
                            required={
                              signupForm["code_phone"].validators.required.value
                            }
                          />
                          <div className="custom-input-box">
                            <select
                              {...register(signupForm["code_phone"].name, {
                                ...signupForm["code_phone"].validators,
                                onChange: (value) => setCodePhone(value),
                                
                              })}
                            >
                              <option value="">
                                {signupForm["code_phone"].placeholder}
                              </option>
                              {countries.map((item) => (
                                <option
                                  key={`code-phone-${item.id}`}
                                  value={item.phone}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          {errors.code_phone && (
                            <div>
                              <label className="form-error">
                                {errors.code_phone.message}
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-8 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={
                              signupForm["phone"].label +
                              (countriesCodePhone
                                ? " +" + countriesCodePhone
                                : "")
                            }
                            required={
                              signupForm["phone"].validators.required.value
                            }
                          />
                          <div className="custom-input-box">
                            <input
                              type="text"
                              {...register(signupForm["phone"].name, {
                                ...signupForm["phone"].validators,
                                onBlur:(event)=>onBlurInputs(event)
                              })}
                              placeholder={signupForm["phone"].placeholder}
                              autoComplete="off"
                            />
                          </div>
                          {errors.phone && (
                            <div>
                              <label className="form-error">
                                {errors["phone"].message}
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <div className="form-custom-box">
                        <FormFloatingLabel
                          label={signupForm["password"].label}
                          required={
                            signupForm["password"].validators.required.value
                          }
                        />
                        <div className="custom-input-box">
                          <IconSecurity
                            width="18px"
                            height="16px"
                            colorIcon="#9891bb"
                          />
                          <input
                            type={passwordType}
                            {...register(signupForm["password"].name, {
                              ...signupForm["password"].validators,
                              onChange: (e) => {
                                handlePasswordChange(e);
                              },
                              onBlur:(event)=>onBlurInputs(event)
                            })}
                            placeholder={signupForm["password"].placeholder}
                            autoComplete="off"
                          />
                          <div className="icon-action" onClick={togglePassword}>
                            {passwordType === "password" ? (
                              <IconBoxEyeOff
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            ) : (
                              <IconBoxEye
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            )}
                          </div>
                        </div>
                        {errors.password && (
                          <label className="form-error">
                            {errors.password.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <div className="form-custom-box">
                        <FormFloatingLabel
                          label={signupForm["re_password"].label}
                          required={
                            signupForm["re_password"].validators.required.value
                          }
                        />
                        <div className="custom-input-box">
                          <IconSecurity
                            width="18px"
                            height="16px"
                            colorIcon="#9891bb"
                          />
                          <input
                            name={signupForm["re_password"].name}
                            type={confirmPasswordType}
                            {...register(signupForm["re_password"].name, {
                              ...signupForm["re_password"].validators,
                              onChange: (e) => {
                                handleConfirmPasswordChange(e);
                              },
                              onBlur:(event)=>onBlurInputs(event),
                              validate: (value) => re_passwordValidate(value),
                            })}
                            placeholder={signupForm["re_password"].placeholder}
                            autoComplete="off"
                          />
                          <div
                            className="icon-action"
                            onClick={toggleConfirmPassword}
                          >
                            {confirmPasswordType === "password" ? (
                              <IconBoxEyeOff
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            ) : (
                              <IconBoxEye
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            )}
                          </div>
                        </div>

                        {errors.re_password && (
                          <div>
                            {errors.re_password.type === "required" && (
                              <label className="form-error">
                                Este campo es requerido
                              </label>
                            )}

                            {errors.re_password.type === "validate" && (
                              <label className="form-error">
                                Las contraseñas no coinciden
                              </label>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="form-custom-box">
                        <FormFloatingLabel
                          label="Coódigo de referido"
                          required={
                            signupForm["referred_by_code"].validators.required
                              .value
                          }
                        />
                        <div className="custom-input-box">
                          <IconReferals
                            width="29px"
                            height="20px"
                            colorIcon="#9891bb"
                          />
                          <input
                            type="text"
                            defaultValue={referredByCode}
                            {...register(signupForm["referred_by_code"].name, {
                              ...signupForm["referred_by_code"].validators,
                              onBlur: (value) => checkCodeReferal(value),
                              onChange: (value) => onChangeCodeReferal(value),
                            })}
                            placeholder={
                              signupForm["referred_by_code"].placeholder
                            }
                            autoComplete="off"
                          />
                        </div>

                        {errors.referred_by_code && (
                          <div>
                            <label className="form-error">
                              {errors.referred_by_code.message}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 chk-tc">
                      <div className="three text-field-box form-box">
                        <div className="chk-tc-box">
                          <Checkbox
                            id="chk"
                            {...register("politica_tratamiento", {
                              required: true,
                            })}
                          />
                        </div>
                        <label htmlFor="chk">
                          Acepto los términos de la{" "}
                          <span style={{ color: "var(--tertiary-main)" }}>
                            Política de tratamiento de datos personales{" "}
                          </span>
                        </label>
                      </div>
                      {errors.politica_tratamiento && (
                        <div>
                          {errors.politica_tratamiento.type === "required" && (
                            <label className="form-error">
                              Debe aceptar las Política de tratamiento de datos
                              personales
                            </label>
                          )}
                        </div>
                      )}
                    </div>

                    <div
                      className="col-md-12 mb-2 mt-4"
                      style={{ display: "flex" }}
                    >
                      <Boton
                        disabled={disableBTN}
                        className="btn button-secondary form-box"
                        label="Continuar"
                        redirect="/dashboard"
                        variant="contained"
                      >
                        Continuar
                      </Boton>
                    </div>
                  </form>

                  <div className="col-md-12 mb-2 mt-4">
                    <Link
                      to={"/register" + (codeRef ? "/" + codeRef : "")}
                      replace={true}
                      className="link"
                    >
                      <span style={{ color: "var(--tertiary-main)" }}>
                        Volver a selección de perfil
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-left box">
          <div className="login-img">
            <div className="img-container">
              <img src={imagen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
