import React from "react";
import styles from "./EtiquetaInvertir.module.css";

const Etiqueta = () => {
  return (
    <div className={styles.etiqueta}>
      <div className={styles.box}>
        <img
          alt=""
          className={styles.vector}
          src="https://static.overlay-tech.com/assets/362afbf3-68e0-486d-8f16-bdfe2dba61d0.svg"
        />
        <label className={styles.text}>Invertir</label>
      </div>
    </div>
  );
};

export default Etiqueta;