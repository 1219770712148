import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { grecapchaSecret } from "../../constants/global";
import Boton from "../Boton";


export const UseReCaptchaV3 = forwardRef((props, ref) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
  
    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      console.log("handleReCaptchaVerify")
      const token = await executeRecaptcha('StayInformed');
      
      const url = `https://www.google.com/recaptcha/api/siteverify?secret=${grecapchaSecret}&response=${token}`;
      return token
      //const recaptchaResponse = await axios.post(url)
  
      /* const requestOptions = {
        method: 'POST'
        };
      fetch(url,requestOptions)
        .then((response) => response.json())
        .then((res) => {
          
        })
        .catch((error) => console.error("Error fetching :", error)); */
      console.log("handleReCaptchaVerify recaptchaResponse",url)
      // Do whatever you want with the token
    }, [executeRecaptcha]);
  
        useImperativeHandle(ref, () => ({
            handleReCaptchaVerify,
      }));
  
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
    return<div></div>
    //return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button> ;
    /* return <Boton
    disabled={false}
    className={"btn _btn-primary font-14"}
    label={"Verify recaptcha"}
    handlerClick={handleReCaptchaVerify}
  /> */
  });